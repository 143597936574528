import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/var/jenkins_home/workspace/affilate_mono_repo_alltomtraning/gatsby/src/components/mdxLayout.js";
import { graphql, Link } from "gatsby";
import ProductsDisplay from "../../components/productDisplay.js";
import CategoriesLinks from "../../components/categoriesLinks.js";
export const query = graphql`
  query {
    allMongodbStoreProducts(sort: {fields: popularity_score, order: DESC}, filter: {brand: { eq: "tunturi" }}) {
      edges {
        node {
          	brand
			full_name
			id
			local_image_name
			model
			original_prices
			popularity_score
			prices
			product_id
			similair_products
			show_on_pages
			sub_product_type
			store
			url
			engine
			last_updated_date
			derived_properties {
				size
				length
				weight
			}
			fields {
				brand_url
				full_name
				full_name_url
				model_url
			}
        }
      }
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "tunturi--din-kompletta-leverantör-av-träningsutrustning"
    }}>{`Tunturi – Din Kompletta Leverantör av Träningsutrustning`}</h1>
    <p>{`Välkommen till vår brand-page för Tunturi, där vi presenterar ett omfattande utbud av högkvalitativ träningsutrustning. Tunturi är känt för sina innovativa och hållbara produkter som gör det möjligt att maximera din träning, oavsett nivå och mål. Utforska de olika produktserierna och hitta den utrustning som passar bäst för dina behov. `}</p>
    <h2 {...{
      "id": "tunturi-träningsutrustning"
    }}>{`Tunturi Träningsutrustning`}</h2>
    <h3 {...{
      "id": "tunturi-utility-series"
    }}>{`Tunturi Utility Series`}</h3>
    <p><strong parentName="p">{`Tunturi Utility UB20, UB40, UB60, UB80`}</strong></p>
    <p>{`Tunturi Utility-serien erbjuder en mängd olika träningsbänkar som täcker allt från grundläggande styrketräningsbehov till mer avancerade träningsrutiner. `}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`UB20`}</strong>{` - En kompakt och hopfällbar träningsbänk, perfekt för både vikt- och magövningar hemma.`}</li>
      <li parentName="ul"><strong parentName="li">{`UB40`}</strong>{` - En justerbar träningsbänk som ger flexibilitet för olika övningar och lätt kan integreras i ditt hemmagym.`}</li>
      <li parentName="ul"><strong parentName="li">{`UB60`}</strong>{` - En robust bänk med högkvalitativa dynor, optimal för både ben- och magövningar.`}</li>
      <li parentName="ul"><strong parentName="li">{`UB80`}</strong>{` - Kombinerar magbänkens funktionalitet med en viktbänk i en platsbesparande design som kan hantera upp till 200 kg maxbelastning.`}</li>
    </ul>
    <h3 {...{
      "id": "tunturi-slam-ball-series"
    }}>{`Tunturi Slam Ball Series`}</h3>
    <p><strong parentName="p">{`Tunturi Slam Ball 5 kg, 10 kg, 15 kg, 20 kg`}</strong></p>
    <p>{`Tunturi Slam Ball-serien är designad för explosiva och dynamiska övningar som förbättrar din styrka, uthållighet och explosivitet. Dessa bollar är fyllda med järnsand och har ett slitstarkt gummihölje för att tåla intensiva träningspass.`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`5 kg`}</strong>{` - Perfekt för nybörjare och grundläggande kraftövningar.`}</li>
      <li parentName="ul"><strong parentName="li">{`10 kg`}</strong>{` - Idealisk för medelnivåträning som kräver mer motstånd.`}</li>
      <li parentName="ul"><strong parentName="li">{`15 kg`}</strong>{` - Utmanar din styrka och kondition, perfekt för avancerade träningspass.`}</li>
      <li parentName="ul"><strong parentName="li">{`20 kg`}</strong>{` - Den ultimata slambollen för toppatleter som söker maximal intensitet och motstånd.`}</li>
    </ul>
    <h3 {...{
      "id": "tunturi-löpband-series"
    }}>{`Tunturi Löpband Series`}</h3>
    <p><strong parentName="p">{`Tunturi TR Series, T Series`}</strong></p>
    <p>{`Tunturi erbjuder olika löpband för både hemmabruk och professionellt bruk, med avancerade funktioner som stödjer din träningsresa.`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`T10 Competence`}</strong>{` - Ett hopfällbart hemmalöpband med 40 förinställda program, hastigheter upp till 16 km/h och en lutningskapacitet på 12%.`}</li>
      <li parentName="ul"><strong parentName="li">{`TR30 Core`}</strong>{` - Ett mångsidigt löpband med dubbelriktad AC-motor, idealiskt för rehabilitering samt intensiv träning. Utrustad med 25 förinställda program, justerbart lutningsintervall och hög maxvikt.`}</li>
    </ul>
    <h3 {...{
      "id": "tunturi-leverage-gym-series"
    }}>{`Tunturi Leverage Gym Series`}</h3>
    <p><strong parentName="p">{`Tunturi WT80 Leverage Gym`}</strong></p>
    <p>{`För den som söker ett allt-i-ett-lösning har Tunturi utvecklat Leverage Gym. Det robusta hemmagymmet tillåter tunga träningspass och är perfekt för både nybörjare och erfarna atleter.`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`WT80 med Viktpaket 107.5 kg`}</strong>{` - Ett komplett multigym med snygg design och en viktkapacitet som enkelt anpassas efter dina träningsbehov.`}</li>
    </ul>
    <h2 {...{
      "id": "köpguiden-hur-väljer-du-rätt-tunturi-serien"
    }}>{`Köpguiden: Hur Väljer Du Rätt Tunturi Serien?`}</h2>
    <p>{`Att välja rätt träningsutrustning kan vara en utmaning. Här är några tips för att hitta den bästa Tunturi-produkten för dig:`}</p>
    <h3 {...{
      "id": "träningsmål-och-nivå"
    }}>{`Träningsmål och Nivå`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Nybörjare`}</strong>{`: Börja med utrustning som är lätt att använda och anpassa, som Tunturi UB20 eller 5 kg Slam Ball.`}</li>
      <li parentName="ul"><strong parentName="li">{`Medelnivå`}</strong>{`: UB40 eller 10 kg Slam Ball för mer intensiva övningar.`}</li>
      <li parentName="ul"><strong parentName="li">{`Avancerade Atleter`}</strong>{`: WT80 Leverage Gym eller 20 kg Slam Ball för maximal intensitet och variation.`}</li>
    </ul>
    <h3 {...{
      "id": "tillgängligt-utrymme"
    }}>{`Tillgängligt Utrymme`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Begränsat Utrymme`}</strong>{`: Välj hopfällbara alternativ som Tunturi UB80 eller T10 Competence löpband.`}</li>
      <li parentName="ul"><strong parentName="li">{`Större Utrymme`}</strong>{`: Då kan du överväga robustare lösningar som WT80 Leverage Gym.`}</li>
    </ul>
    <h3 {...{
      "id": "träningspreferenser"
    }}>{`Träningspreferenser`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Styrketräning`}</strong>{`: Tunturi Utility bänkar eller Leverage Gym.`}</li>
      <li parentName="ul"><strong parentName="li">{`Konditionsträning`}</strong>{`: T Series eller TR Series löpband.`}</li>
      <li parentName="ul"><strong parentName="li">{`Kraft och Explosivitet`}</strong>{`: Tunturi Slam Ball-serien.`}</li>
    </ul>
    <p>{`Uppgradera din träningsutrustning idag med Tunturis omfattande sortiment och ta ditt träningsrutin till nästa nivå! Upptäck kraften i kvalitet och prestanda genom att välja rätt Tunturi-serie för dina träningsbehov.`}</p>
    <ProductsDisplay data={props.data.allMongodbStoreProducts} location={props.location} mdxType="ProductsDisplay"></ProductsDisplay>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      